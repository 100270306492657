import * as React from 'react';
import { css } from '@emotion/react';
import Text from '../../atoms/Text/Text';
import Icon from '../../atoms/Icon/Icon';
import Button from '../../atoms/Button/Button';

export default function DescripcionProyecto({
  ocultarCampos,
  direction,
  area,
  maxDormitorio,
  status,
  distrito,
  linkPdf,
  linkTour,
  dormitorios,
  titlePage,
  isAnimado,
  textTour,
  textTour2,
  linkTour2,
  textAvance,
  linkAvance,
  estadoId,
}) {
  const sectionCss = css`
    background-color: #5e5e5e;
    padding-top: calc(100vw * (calc(45 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(40 / var(--width_base))));
    font-family: 'Montserrat Regular', sans-serif;
    @media (max-width: 767px) {
      background-color: #444443;
      padding-left: calc(100vw * (calc(30 / var(--width_base))));
      padding-right: calc(100vw * (calc(30 / var(--width_base))));
    }
  `;

  const contentCss = css`
    display: flex;
    max-width: calc(100vw * (calc(1020 / var(--width_base))));
    margin-left: auto;
    margin-right: auto;
    flex-wrap: wrap;
    row-gap: calc(100vw * (calc(50 / var(--width_base))));
    @media (max-width: 767px) {
      flex-direction: column;
      row-gap: calc(100vw * (calc(20 / var(--width_base))));
    }
  `;

  const itemCss = css`
    width: 50%;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    // margin-bottom: calc(100vw * (calc(50 / var(--width_base))));
    @media (max-width: 767px) {
      width: 100%;
      // margin-bottom: calc(100vw * (calc(20 / var(--width_base))));
    }
  `;

  const numCss = css`
    font-size: calc(100vw * (calc(29 / var(--width_base))));
    line-height: calc(100vw * (calc(29 / var(--width_base))));
    color: white;
    font-family: 'Montserrat Bold', sans-serif;
    position: relative;

    &:not(:last-of-type) {
      margin-right: calc(100vw * (calc(15 / var(--width_base))));
      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: calc(100vw * (calc(-10 / var(--width_base))));
        margin-top: auto;
        margin-bottom: auto;
        display: block;
        width: calc(100vw * (calc(4.42 / var(--width_base))));
        height: calc(100vw * (calc(4.42 / var(--width_base))));
        background-color: #93ba1f;
        border-radius: 50%;
      }
    }
  `;

  const textCss = css`
    font-size: calc(100vw * (calc(20 / var(--width_base))));
    line-height: calc(100vw * (calc(20 / var(--width_base))));
    @media (max-width: 767px) {
      font-size: calc(100vw * (calc(14 / var(--width_base))));
      line-height: calc(100vw * (calc(17 / var(--width_base))));
    }
  `;

  const textAreaCss = css`
    ${textCss};
    & {
      line-height: calc(100vw * (calc(26 / var(--width_base))));
    }
    @media (max-width: 767px) {
      & {
        line-height: calc(100vw * (calc(24 / var(--width_base))));
      }
    }
  `;

  const distritoCss = css`
    ${textCss};
    width: 100%;
    margin-top: calc(100vw * (calc(5 / var(--width_base))));
  `;

  const iconContentCss = css`
    min-width: calc(100vw * (calc(96 / var(--width_base))));
    display: flex;
    justify-content: center;
    margin-right: calc(100vw * (calc(32 / var(--width_base))));
    @media (max-width: 767px) {
      margin-right: calc(100vw * (calc(15 / var(--width_base))));
    }
  `;

  const groupBtnCss = css`
    display: flex;
    flex-wrap: wrap;
    gap: calc(100vw * (calc(15 / var(--width_base))));
    justify-content: center;
    margin-top: calc(100vw * (calc(50 / var(--width_base))));
    @media (max-width: 767px) {
      margin-top: calc(100vw * (calc(20 / var(--width_base))));
      display: block;
    }

    & a {
      @media (max-width: 767px) {
        min-width: calc(100vw * (calc(250 / var(--width_base))));
        text-align: center;
        justify-content: center;
      }
    }
  `;

  let mostrarDormitorios = [];
  mostrarDormitorios =
    dormitorios &&
    dormitorios.map((dormitorio) => (
      <span css={numCss}>{dormitorio?.name}</span>
    ));

  const btnCss = css`
    @media (max-width: 767px) {
      margin-top: calc(100vw * (calc(25 / var(--width_base))));
    }
  `;

  const itemContentCard = css`
    display: flex;
    align-items: center;
    width: calc(100vw * (calc(340 / var(--width_base))));
    margin-left: auto;
  `;

  const iconContent2Css = css`
    justify-content: flex-start;
    @media (max-width: 767px) {
      justify-content: center;
    }
  `;

  return (
    <section css={sectionCss}>
      {titlePage && (
        <Text
          classEle="d-none"
          data={`Descripción ${titlePage}`}
          typeEle="h2"
        />
      )}
      <div css={contentCss}>
        {direction && (
          <div
            css={itemCss}
            data-aos={isAnimado && 'fade-right'}
            data-aos-delay="200"
          >
            <div css={iconContentCss}>
              <Icon typeEle="mapa" />
            </div>
            <div>
              <Text data={direction} colorText="white" styleCss={textCss} />
              <Text
                data={distrito}
                colorText="#93BA1F"
                styleCss={distritoCss}
              />
            </div>
          </div>
        )}

        {!ocultarCampos && (
          <>
            {dormitorios?.length > 0 && maxDormitorio && (
              <div
                className="only-desk"
                css={itemCss}
                data-aos={isAnimado && 'fade-left'}
                data-aos-delay="200"
              >
                <div css={itemContentCard}>
                  <div
                    css={css`
                      ${iconContentCss}${iconContent2Css}
                    `}
                  >
                    {mostrarDormitorios}
                  </div>
                  <Text
                    data="Dormitorios"
                    colorText="white"
                    styleCss={textCss}
                  />
                </div>
              </div>
            )}
          </>
        )}

        {status && (
          <div
            css={itemCss}
            data-aos={isAnimado && 'fade-right'}
            data-aos-delay="200"
          >
            <div css={iconContentCss}>
              <Icon typeEle="estado" />
            </div>
            <Text data={status} colorText="white" styleCss={textCss} />
          </div>
        )}

        {!ocultarCampos && (
          <>
            {area && (
              <div
                css={itemCss}
                data-aos={isAnimado && 'fade-left'}
                data-aos-delay="200"
              >
                <div css={itemContentCard}>
                  <div
                    css={css`
                      ${iconContentCss}${iconContent2Css}
                    `}
                  >
                    <Icon typeEle="area" />
                  </div>
                  <Text data={area} colorText="white" styleCss={textAreaCss} />
                </div>
              </div>
            )}
          </>
        )}
      </div>
      {!ocultarCampos && (
        <div className="text-center" css={groupBtnCss}>
          <Button
            typeEle="pdf"
            styleTypeEle="pdf"
            link={linkPdf}
            data-aos={isAnimado && 'fade-right'}
            data-aos-delay="200"
          >
            <span className="only-desk">Descargar Brochure</span>
            <span className="only-mov">Ver Brochure</span>
          </Button>
          { titlePage != 'Eco Style 2' && <Button
            typeEle="linkTarget"
            styleTypeEle="pdf"
            link={linkTour}
            styleEle={btnCss}
            data-aos={isAnimado && 'fade-left'}
            data-aos-delay="200"
          >
            <span>{textTour ? textTour : 'TOUR 360° DPTO PILOTO'} </span>
          </Button>}
          {linkTour2 && (
            <Button
              typeEle="linkTarget"
              styleTypeEle="pdf"
              link={linkTour2}
              styleEle={btnCss}
              data-aos={isAnimado && 'fade-left'}
              data-aos-delay="200"
            >
              <span>{textTour2 ? textTour2 : 'TOUR 360° DPTO PILOTO'} </span>
            </Button>
          )}
          {linkAvance && (
            <Button
              typeEle="linkTarget"
              styleTypeEle="pdf"
              link={linkAvance}
              styleEle={btnCss}
              data-aos={isAnimado && 'fade-left'}
              data-aos-delay="200"
            >
              <span>{textAvance ? textAvance : 'TOUR 360° DPTO PILOTO'} </span>
            </Button>
          )}
        </div>
      )}
    </section>
  );
}

DescripcionProyecto.defaultProps = {
  isAnimado: false,
};
